import { Center } from "@mantine/core"
import { DateTime } from "luxon"
import React, { useEffect, useState } from "react"
import { Loader } from "src/components"
import { isHighnoteProdEnv } from "src/config"
import { useAppDispatch, useAppSelector } from "src/hooks/redux"
import {
  selectLoggedInUserName,
  selectPermanentPaymentCardViewDetailsToken,
  selectTemporaryPaymentCardViewDetailsToken,
  thunkGetPaymentCardToken,
} from "src/store"
import { PaymentCard } from "src/types"
import CardImage from "./CardImage"
import { getAccountHolderStatusInfo } from "src/api"

type Props = {
  card: PaymentCard
}

const DEFAULT_CARD_TOKEN_LIFESPAN_MINUTES = 15
const CardImageContainer: React.VFC<Props> = ({
  card: { id: cardId, lifecycle },
}) => {
  const dispatch = useAppDispatch()
  const selectorFn =
    lifecycle === "TEMPORARY"
      ? selectTemporaryPaymentCardViewDetailsToken
      : selectPermanentPaymentCardViewDetailsToken
  const viewCardDetailsToken = useAppSelector(selectorFn)
  const loggedInUserName = useAppSelector(selectLoggedInUserName)
  const [cardDetailsTokenValid, setCardDetailsTokenValid] = useState(false)
  //const [isKyc,setIsKyc] = useState(true)
  const [useVisa,setUseVisa] = useState(true);
  const [isInitialized, setInitialized] = useState(false);
  const [isPageReady, setPageReady] = useState(false);
  const [tokenValue, setTokenValue] = useState("");

  useEffect(() => {
    if(viewCardDetailsToken != undefined){
      if(viewCardDetailsToken && isInitialized  
        && useVisa){
          setTokenValue(viewCardDetailsToken.value);
          setPageReady(true);
          console.log("Is KYC")
          console.log(useVisa)
          console.log("Page is ready")
      }else if(viewCardDetailsToken && isInitialized  
        && !useVisa){
          setTokenValue(viewCardDetailsToken.value);
          setPageReady(true);
          console.log("Is KYC")
          console.log(useVisa)
          console.log("Page is ready")
      }
    }
  }, [viewCardDetailsToken,useVisa])


  useEffect(() => {
    if (!cardDetailsTokenValid) {
      dispatch(thunkGetPaymentCardToken(lifecycle, "READ_RESTRICTED_DETAILS"))
      setCardDetailsTokenValid(true)
    }

    const tokenExpirationIntervalMinutes = viewCardDetailsToken
      ? DateTime.fromISO(viewCardDetailsToken.expiresAt).diffNow().as("minutes")
      : DEFAULT_CARD_TOKEN_LIFESPAN_MINUTES

    const timer = setTimeout(() => {
      setCardDetailsTokenValid(false)
    }, (tokenExpirationIntervalMinutes - 1) * 60_000)

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [cardDetailsTokenValid])

  useEffect(() => {
    getAccountHolderStatuses();
  }, [])

  function getAccountHolderStatuses(){
    if(!isInitialized){
      getAccountHolderStatusInfo().then(res => {
        if(res?.success == true){
          console.log(res);
          if(res?.isNondescriptUser){
            setUseVisa(true);
          }else{
            setUseVisa(false);
          }

          setInitialized(true);
        }
      })
    }
  }

  return (
    <>
      {!isPageReady && (
        <Center>
          <Loader />
        </Center>
      )}
      {isPageReady && loggedInUserName && (
        <CardImage
          cardHolderName={loggedInUserName}
          clientToken={tokenValue}
          paymentCardId={cardId}
          isHighnoteProdEnv={isHighnoteProdEnv}
          isVisa={useVisa}
        />
      )}
    </>
  )
}

export default CardImageContainer
